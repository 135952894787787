@import url('https://fonts.googleapis.com/css2?family=Righteous&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Monaco&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

@import url('./Styles.css');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  :root {
  --main-black:#000000;
  --main-orange:#FF5C00;
  --main-purple:#5643E9;
  --slides-purple:#a49ed2; 
  --nav-border: #c6c6c6;
  --img-back:#FFFFF4;
  --glass-white:rgba(106, 101, 101, 0.214),rgba(112, 107, 112, 0.344); 
  --glass-secondary:rgb(87, 10, 94),rgb(143, 64, 50);
  --glass-before:rgba(16, 15, 15, 0.146);
  --blur:hsla(0, 0%, 100%, 0.03);
  --idea-grey: #5F5C76;
  --idea-grey-underline: #6d6d6d;
  }
  
  *::after,*::before {
  box-sizing: border-box;
  }
  
  img,picture {
   max-width: 100%;
  }


.home {
    background: var(--img-back);
    width: 100%;
    min-height: 100vh;
    background-size: cover;
    background-position: center;
    position: sticky;
    top: 0;
    font-family: Righteous;
}

.home__box {
    display: flex;
    align-items: center;
    margin: 0rem 6rem;
    position: relative;
}

@media (min-width:1000px) {
    .home__img {
        width:720px;
        margin-left: 1rem;
    }
    .home__content {
        margin-left: 1rem;
        width: 550px;
    }
    .content__header {
        margin-left: 20%;
        font-size: 40px;
        font-weight: lighter;
        color: var(--main-orange);
        text-transform: lowercase;
        line-height: 75px;
        text-decoration: underline;
        text-decoration-thickness: 1px;
        text-underline-offset: 7px;
        text-decoration-color: var(--main-orange);
        letter-spacing: 1px;
    }

    .home__content nav{
        color: var(--idea-grey);
    }
    
    .home__content nav ul{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right:20%;
    }
    .home__content nav li{ 
        list-style: none;
        padding-inline: 1rem;
        line-height: 35px;
    }
    
    .home__content nav li a{
        text-decoration: none;
        color: var(--idea-grey);
        font-size: 17px;
        font-family: Righteous;
    }
    
    .home__content nav li a:hover{
        text-decoration: underline;
    }
}

@media (max-width:1000px) {
    .home__box {
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }
    .home__img {
        margin-top:20%;
        width: 260px;
    }
    .home__content {
        text-align: center;
        padding:30px;
    }
    .content__header {
        color: var(--main-orange);
        padding-bottom: 6px;
    }

    .home__content nav li{ 
        list-style: none;
        padding-inline: 1rem;
        line-height: 20px;
        padding-top: 5px;
        padding-bottom: 5px;
    }
    
    .home__content nav li a{
        text-decoration: none;
        color: var(--idea-grey);
        font-size: 15px;
        font-family: Righteous;
    }
    
    .home__content nav li a:hover{
        text-decoration: underline;
    }
}


@media (max-width:550px) {
    /* .home__content button {
        padding:10px 20px;
        margin-right: .5rem;
    } */
    .home__img {
        margin-top:20%;
        width: 220px;
    }
    .home__content nav li a{
        text-decoration: none;
        color: var(--idea-grey);
    }
    /* .home__content .small__p {
        font-size: 8px;
    } */
    /* .home__content p {
        font-size: 7px;
        margin-top: 1rem;
    } */
    .content__header {
        font-size: 25px;
    }
}