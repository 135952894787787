.GridContainer {
  display: grid;
  grid-template-columns: 15% 67% 18%;
  grid-column-gap: 5px;
  color: var(--idea-grey);
  border-bottom: 0.5px dotted var(--idea-grey-underline);
  align-items: end;
  width: 100%;
  justify-items: left;
  margin-bottom: 2%;
  text-decoration:none;
}

.GridContainer:hover{
  cursor: pointer;
  color: var(--main-purple);
} 

.Writing{
  font-family: Poppins;
  font-size: 13px;
  line-height: 1.5;
  color: var(--idea-grey);
}

.SubHeading{
  font-style: oblique;
}

.Writing ol, .Writing ul {
  padding-left: 5%;
}

.Writing p {
  /* padding-left: 1%; */
  margin-bottom: 20px; /* Add extra space below each paragraph */
}



