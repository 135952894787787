@import url(https://fonts.googleapis.com/css2?family=Righteous&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Monaco&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
.home__icon h1{
    font-size:30px;
    color: var(--main-orange);
    letter-spacing: 1px;
    font-weight: lighter;
}

.home__icon h1:hover{
    text-decoration: underline;
    -webkit-text-decoration-color: var(--main-orange);
            text-decoration-color: var(--main-orange);
}

@media (min-width: 50em) {
    .navbar ul {
        margin: 0 -10px;
        border-bottom: 1px solid var(--nav-border);
        align-items: center;
    }
    nav li {
        margin: 0 20px;
    }
    .home__icon {
        margin-right: auto;
    } 
}

@media (min-width:50em) {
    .list__items {
        display: flex;
        padding-inline: 2rem;
        padding-block: 1.5rem;
        grid-gap: 3rem;
        gap: 3rem;
        list-style: none;
        background: var(--blur);
        -webkit-backdrop-filter: blur(.3rem);
                backdrop-filter: blur(.3rem);
        margin: 0;
    }

    .menu_icon {
        display: none;
    }

    .close_icon{
        display: none;
    }


}

.list__items li a {
    text-decoration: none;
    color: var(--idea-grey);
    font-size: 17px;
    font-family: Righteous;
}

.nav__links{
    margin-left: 10rem;
    flex:auto;
}

.list__items li a:hover {
    text-decoration: underline;
}

/* @media (width: 50em){

} */

@media (max-width:50em) {
    .navbar {
        display: flex;
    }
    .list__items {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        position: fixed;
        inset: 0 0 0 0%;

        grid-gap: 1.5rem;

        gap: 1.5rem;
        list-style: none;        

        background-color: var(--img-back);
        border: 1px solid var(--nav-border);

        transform: translateY(100%);
        transition: 400ms;
    }

    .list__items.active {
        transform: translateX(0);
        transition: 450ms;
    }

    .menu_icon {
        position: absolute;
        right: 2rem;
        top: 1.5rem;
        color: var(--main-black);
        cursor: pointer;
    }

    .menu_icon .menu {
        font-size: 40px;
    }
    .close_icon {
        color: var(--main-black);
        cursor: pointer;
        position: fixed;
        top: 2rem;
        right: 2rem;
    }
}
.ideas {
  width: 100%;
  min-height: 100vh;
  background: var(--img-back);
  position: relative;
  overflow: hidden;
}

.bookshelf {
  margin-left: auto;
  margin-right: auto; 
  max-width: min(800px, 90%);
  margin-bottom: 50px;
}


.cards {
  padding-top: 1%;
}


.cards h1 {
  color: var(--main-purple);
  font-family: Righteous;
  font-size: 50px;
  font-weight:lighter;
  margin-top:3rem;
}

.cards h3 pre, span, a {
  font-family: Poppins;
  font-weight: 100;
  font-size: 13px;
}

.LinkListItem{
  margin-bottom: 30px; 
  margin-left: 15px;
}

.standardized-spacing {
  grid-column: span 2;
  grid-row: span 2;
}

@media (min-width:900px) {
  .ideas__container {
      display: grid;
      margin-block: 1rem;
      grid-template-columns: repeat(auto-fit,minmax(35rem,1fr));
      grid-gap: 1rem;
      gap: 1rem;
  }
}

@media (max-width:900px) {
  .ideas__container {
      padding:20px;
      grid-gap:2rem;
      gap:2rem;
  }
  .cards {
      margin-block: 1rem;
  }
}
.bookshelf_GridContainer__3e0O7 {
  display: grid;
  grid-template-columns: 15% 67% 18%;
  grid-column-gap: 5px;
  color: var(--idea-grey);
  border-bottom: 0.5px dotted var(--idea-grey-underline);
  align-items: end;
  width: 100%;
  justify-items: left;
  margin-bottom: 2%;
  text-decoration:none;
}

.bookshelf_GridContainer__3e0O7:hover{
  cursor: pointer;
  color: var(--main-purple);
} 

.bookshelf_Writing__9bNhi{
  font-family: Poppins;
  font-size: 13px;
  line-height: 1.5;
  color: var(--idea-grey);
}

.bookshelf_SubHeading__xNcDc{
  font-style: oblique;
}

.bookshelf_Writing__9bNhi ol, .bookshelf_Writing__9bNhi ul {
  padding-left: 5%;
}

.bookshelf_Writing__9bNhi p {
  /* padding-left: 1%; */
  margin-bottom: 20px; /* Add extra space below each paragraph */
}




.thoughts_Layout__12NJA {
  margin-left: 12.5%;
  margin-right: 10%; 
  max-width: min(800px, 90%);
}

.thoughts_GridContainer__z_FiD {
  display: grid;
  grid-template-columns: 20% 80%;
  color: var(--idea-grey);
  border-bottom: 0.5px dotted var(--idea-grey-underline);
  align-items: end;
  width: 100%;
  justify-items: left;
  margin-bottom: 2%;
  text-decoration: none;
}

.thoughts_GridContainer__z_FiD:hover{
  cursor: pointer;
  color: var(--main-purple);
} 

/* TODO: these are called out in the cap touch.js file. And are specific to that use case (only slides and no text below the slides.
          betware of the margin-bottom to give some margin to the last bottom slide. */
.thoughts_CapTouchSlide__339o_{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center; 
  align-items: center;
  margin: 0 auto;
  margin-bottom: 50px;

}

.thoughts_CapTouchImage__1-7Xg{ 
  justify-content: center;
  align-items: center;
  width: 75%;
  border: 0.7px solid var(--main-purple);
  border-radius: 10px;
}

.thoughts_Writing__2_qGN{
  font-family: Poppins;
  font-size: 13px;
  line-height: 1.5;
  color: var(--idea-grey);
}

.thoughts_Writing__2_qGN ol, .thoughts_Writing__2_qGN ul {
  padding-left: 5%;
}

.thoughts_Writing__2_qGN p {
  /* padding-left: 1%; */
  margin-bottom: 20px; /* Add extra space below each paragraph */
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  :root {
  --main-black:#000000;
  --main-orange:#FF5C00;
  --main-purple:#5643E9;
  --slides-purple:#a49ed2; 
  --nav-border: #c6c6c6;
  --img-back:#FFFFF4;
  --glass-white:rgba(106, 101, 101, 0.214),rgba(112, 107, 112, 0.344); 
  --glass-secondary:rgb(87, 10, 94),rgb(143, 64, 50);
  --glass-before:rgba(16, 15, 15, 0.146);
  --blur:hsla(0, 0%, 100%, 0.03);
  --idea-grey: #5F5C76;
  --idea-grey-underline: #6d6d6d;
  }
  
  *::after,*::before {
  box-sizing: border-box;
  }
  
  img,picture {
   max-width: 100%;
  }


.home {
    background: var(--img-back);
    width: 100%;
    min-height: 100vh;
    background-size: cover;
    background-position: center;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    font-family: Righteous;
}

.home__box {
    display: flex;
    align-items: center;
    margin: 0rem 6rem;
    position: relative;
}

@media (min-width:1000px) {
    .home__img {
        width:720px;
        margin-left: 1rem;
    }
    .home__content {
        margin-left: 1rem;
        width: 550px;
    }
    .content__header {
        margin-left: 20%;
        font-size: 40px;
        font-weight: lighter;
        color: var(--main-orange);
        text-transform: lowercase;
        line-height: 75px;
        text-decoration: underline;
        text-decoration-thickness: 1px;
        text-underline-offset: 7px;
        -webkit-text-decoration-color: var(--main-orange);
                text-decoration-color: var(--main-orange);
        letter-spacing: 1px;
    }

    .home__content nav{
        color: var(--idea-grey);
    }
    
    .home__content nav ul{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right:20%;
    }
    .home__content nav li{ 
        list-style: none;
        padding-inline: 1rem;
        line-height: 35px;
    }
    
    .home__content nav li a{
        text-decoration: none;
        color: var(--idea-grey);
        font-size: 17px;
        font-family: Righteous;
    }
    
    .home__content nav li a:hover{
        text-decoration: underline;
    }
}

@media (max-width:1000px) {
    .home__box {
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }
    .home__img {
        margin-top:20%;
        width: 260px;
    }
    .home__content {
        text-align: center;
        padding:30px;
    }
    .content__header {
        color: var(--main-orange);
        padding-bottom: 6px;
    }

    .home__content nav li{ 
        list-style: none;
        padding-inline: 1rem;
        line-height: 20px;
        padding-top: 5px;
        padding-bottom: 5px;
    }
    
    .home__content nav li a{
        text-decoration: none;
        color: var(--idea-grey);
        font-size: 15px;
        font-family: Righteous;
    }
    
    .home__content nav li a:hover{
        text-decoration: underline;
    }
}


@media (max-width:550px) {
    /* .home__content button {
        padding:10px 20px;
        margin-right: .5rem;
    } */
    .home__img {
        margin-top:20%;
        width: 220px;
    }
    .home__content nav li a{
        text-decoration: none;
        color: var(--idea-grey);
    }
    /* .home__content .small__p {
        font-size: 8px;
    } */
    /* .home__content p {
        font-size: 7px;
        margin-top: 1rem;
    } */
    .content__header {
        font-size: 25px;
    }
}
.aboutme_PagePadding__2RYpw{
  margin-left: auto;
  margin-right: auto; 
  max-width: min(800px, 90%);
  margin-bottom: 50px;
}

.aboutme_Writing__2BqiC{
  font-family: Poppins;
  font-size: 13px;
  line-height: 1.5;
  color: var(--idea-grey);
}

.aboutme_Writing__2BqiC ol, .aboutme_Writing__2BqiC ul {
  padding-left: 5%;
}

.aboutme_Writing__2BqiC p {
  /* padding-left: 1%; */
  margin-bottom: 20px; /* Add extra space below each paragraph */
}

.aboutme_Link__2U4Ez { 
  -webkit-text-decoration-color: var(--idea-grey); 
          text-decoration-color: var(--idea-grey);
}
.Scratchpad_GridContainer__Cusru {
  display: grid;
  grid-template-columns: 20% 80%;
  border-bottom: 0.5px dotted var(--idea-grey-underline);
  align-items: end;
  width: 100%;
  justify-items: left;
  margin-bottom: 2%;
  color: var(--idea-grey);
}

.Scratchpad_Link__2QMiR{
  text-decoration: none;
  color: var(--idea-grey);
}

.Scratchpad_Link__2QMiR:hover{
  color:var(--main-purple);
  cursor: pointer;
}
