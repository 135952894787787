.GridContainer {
  display: grid;
  grid-template-columns: 20% 80%;
  border-bottom: 0.5px dotted var(--idea-grey-underline);
  align-items: end;
  width: 100%;
  justify-items: left;
  margin-bottom: 2%;
  color: var(--idea-grey);
}

.Link{
  text-decoration: none;
  color: var(--idea-grey);
}

.Link:hover{
  color:var(--main-purple);
  cursor: pointer;
}